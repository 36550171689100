const SubjectScore = (props) => {
  const data = props.data;
  function convertToNumber(variable) {
    let number = parseFloat(variable); // or Number(variable)
    if (isNaN(number)) {
      return 0;
    }
    return number;
  }

  const style = {
    width: "30px",
    textAlign: "center",
    height: "8px",
    fontSize: "12px"
  };

  return (
    <>
      <tr>
        <td style={style} >{ data.subject_name }</td>
        <td style={style} >{ data.first_grade }</td>
        <td style={style} >{ data.second_grade }</td>
        <td style={style} >{ data.third_grade }</td>
        <td style={style} >{ data.fourth_grade }</td>
        <td style={style} >{ data.exam_grade }</td>
        <td style={style} >{ data.final_grade }</td>
        <td style={style} >{ data.name }</td>
        <td style={style} >{ data.description }</td>
      </tr>      
    </>
  );
};
export default SubjectScore;