import { useLocation } from "react-router-dom";
//import './result.css';
import './styles/resultlaurel.css';
import AssessmentFinalLaurel from "./subcomponents/AssessmentFinalLaurel";
import BehaviourLaurel from "./subcomponents/BehaviourLaurel";
import OtherinfoLaurel from "./subcomponents/OtherinfoLaurel";
const ResultViewFinalLaurel = (props) => {
  const location = useLocation();
  console.log(location.state);
  const admission = location.state.admission;
  const profile = location.state.profile;
  const school = location.state.school;
  const history = location.state.history;
  const behaviour = location.state.behaviour;
  const assessment = location.state.records;
  const grading = location.state.grading;
  const teacher = location.state.teacher;
  const section_id = String(location.state.section_id);
  const session_id = String(location.state.session_id);

  let section_name = '';
  let session_name = '';
  
  for (let h = 0; h < history.length; h++) {
    const hsec_id = String(history[h].section_id);
    const hses_id = String(history[h].session_id);
    if (section_id === hsec_id) {
      section_name = history[h].section_name;
    }
    if (session_id === hses_id) {
      session_name = history[h].session_name;
    }
  }

  function convertToNumber(variable) {
    let number = parseFloat(variable); // or Number(variable)
    if (isNaN(number)) {
      return 0;
    }
    return number;
  }
  
  function getStudentPencentage(assessment){
    let totalfinal_grade = 0;
    let totalpossible_grade = 0;
    for (let i = 0; i < assessment.length; i++) {
      totalfinal_grade += convertToNumber(assessment[i].final_grade);
    }
    totalpossible_grade = assessment.length * 100;
    return ((totalfinal_grade/totalpossible_grade) * 100).toFixed(2);
  }
  
  const alignstyle = {
    textAlign: "center"
  };

  const tablestyle = {
    fontSize: "8px",
    padding: "0"
  }

  const tableborderstyle = {
    borderColor: "rgba(98, 98, 98, 0.402)"
  }

  const table13h6style = {
    fontSize: "13px"
  }

  const imgpassportstyle = {
    height: "100px",
    width: "100px"
  }

  const tablebordercolorstyle = {
    borderColor: "#b6b4b498"
  }

  const markfontstyle = {
    fontSize: "6px"
  }

  return (
    <body className="p-1">
      <div className="border border-1 border-secondary" id="container">
        <div className="row m-0 align-items-center py-4 hero">
          <div className="col-3">
            {/*<!-- School Logo -->*/}
            { school.school_logo == null ? null : <img src={ 'https://early.school/cnd/schools_assets/schools_images/schools_sites_images/'+school.school_logo} width="70" />}
          </div>
          <div className="col-9 text-center">
            <h1 className="text-danger h2 school_name">{school.school_name}</h1>
            <h6>{school.school_address}</h6>
            <h5>Report Sheet</h5>
          </div>
        </div>

        <div className="m-0 d-flex px-4 align-items-center">
          <div className="col-8">
            {/*<!-- Students Password -->*/}
            { profile.profile_image == null ? null : <img src={ 'https://early.school/cnd/schools_assets/schools_images/students_passport_images/'+profile.profile_image } width="70" style={imgpassportstyle} alt="Passport" />}
            <p className="m-1">
              {/*<!-- Students Name -->*/}
              Name of Student:
              <span className="px-2 text-uppercase"><u><b>{profile.first_name} {profile.last_name}</b></u></span>
              {/*<!-- Admission Number -->*/}
              Adm No. :
              <span className="px-2 text-uppercase"><u><b>{ admission.admission_number }</b></u></span>
              {/*<!-- Students Sex -->*/}
              Sex :
              <span className="px-2 text-uppercase"><u><b>{profile.gender}</b></u></span>
            </p>
            <p className="m-1">
              {/*<!-- Students Name -->*/}
              Class:
              <span className="px-2 text-uppercase"><u><b>{ history.class_name }</b></u></span>
              {/*<!-- Admission Number -->*/}
              Session :
              <span className="px-2 text-uppercase"><u><b>{ history.session_name }</b></u></span>
              {/*<!-- Students Sex -->*/}
              Term :
              <span className="px-2 text-uppercase"><u><b>{ history.section_name }</b></u></span>
            </p>
            <p className="m-1">
              {/*<!-- Next Term Resumption Date -->*/}
              Next Term Begins On:
              <span className="px-2 text-uppercase"><u><b>10th January 2023</b></u></span>
              {/*<!-- Overall Percentage Number -->*/}
              Overall Percentage :
              <span className="px-2 text-uppercase"><u><b>{ getStudentPencentage }</b></u></span>
            </p>
          </div>
          <div className="col-4">
            <h6 className="text-center" style={table13h6style} >
            { session_name } ACADEMIC COMMULATIVE RESULT
            </h6>
            <div className="table-container" style={tablestyle} >
              <table className="table-bordered" style={tableborderstyle}>
                <tbody>
                  <tr scope="row">
                    <th width="20%">1st Term (100%)</th>
                    <th width="20%">2nd Term (100%)</th>
                    <th width="20%">3rd Term (100%)</th>
                    <th width="20%">Overall Average (100%)</th>
                    <th width="20%">Remarks</th>
                  </tr>
                  <tr>
                    <td>70%</td>
                    <td>70%</td>
                    <td>70%</td>
                    <td>70%</td>
                    <td>Excellent</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        { assessment!==null ? 
            <AssessmentFinalLaurel 
              key= {assessment.key}
              data= {assessment}
              gradingdata= {grading}
            />
            : null }

        { behaviour!==null ? 
            <BehaviourLaurel
              key={behaviour.id}
              data= {behaviour}
              gradingdata= {grading}
            />
        : null }

        { behaviour!==null ? 
            <OtherinfoLaurel
              key={behaviour.id}
              data= {behaviour}
              teacher= {teacher}
              grading ={grading}
            />
            : null }
        
      </div>
    </body>
  );
};
export default ResultViewFinalLaurel;