import GradingView from "./GradingView";
const Otherinfo = (props) => {
  const data = props.data;
  const grading = props.grading;
  const teacher = props.teacher;
  const alignstyle = {
    textAlign: "center"
  };
  
  return (
    <>
      <div className="key-to-grade">
      { grading === null|{} ?
        null
        :
        <>
          <p className="Key">Key to Grades</p>
          <div className="key-to">
            <table className="">
              <tbody>
                { grading.map((data) => 
                  <GradingView 
                    key={data.id}
                    data={data}
                  />
                )}
              </tbody>
            </table>
          </div>
        </>
        }
        <div className="payable-fees">
          { data.custom_next_term_textbook_fee !== null ? 
            <div className="next-time-fees">
              <p className="fees">Next Term Fee</p>
              <p className="comment">{ data.custom_next_term_textbook_fee }</p>
            </div>
          : null }
          { data.custom_student_school_fees !== null ? 
            <div className="next-term-textbook-fees">
              <p className="fees">Next Term Textbook Fee</p>
              <p className="comment">{ data.custom_student_school_fees }</p>
            </div>
          : null }
          { data.custom_school_resume !== null ? 
            <div className="payable">
              <p className="pay">Next Term Resumes</p>
              <p className="comment">{ data.custom_school_resume }</p>
            </div>
          : null }
          { data.payable_before !== null ? 
            <div className="payable">
              <p className="pay">Payable on or Before</p>
              <p className="comment">{ data.payable_before }</p>
            </div>
          : null }
          { data.no_of_times_school_open !== null ? 
            <div className="No-of-time-school-open">
              <p className="sess-sect">No. of time school opened</p>
              <p className="comment">{ data.no_of_times_school_open }</p>
            </div>
          : null }
          { data.days_present_in_school !== null ? 
            <div className="total-attendance">
              <p className="sess-sect">Total Attendance</p>
              <p className="comment">{ data.days_present_in_school }</p>
            </div>
          : null }
          { data.days_present_in_school !== null ? 
            <div className="attendance-in-percent">
              <p className="sess-sect">% Attendance</p>
              <p className="comment">{ data.attendance_in_percent }</p>
            </div>
          : null }
        </div>
      </div>
      <div className="flex-comment">
        { data.teacher_comment !== null ? 
          <div className="class-teacher-comment"><p className="teacher-comment">Class Teacher's Comment</p>{data.teacher_comment}</div>
        : null }
        { teacher.first_name !== null ? 
          <div className="class-teacher-name"><p className="teacher-name">Class Teacher's Name</p>{teacher.first_name} {teacher.last_name}</div>
        : null }
        { teacher.teacher_signature !== null ? 
          <div className="signature">
            <p className="sign">Signature</p>
            { teacher.teacher_signature == null ? null : <img src={ 'https://early.school/cnd/schools_assets/schools_images/teachers_sign_images/'+teacher.teacher_signature } width="30" height="30" />}
            <div className="commentsss"></div>
          </div>
        : null }
        { data.head_teacher_comment !== null ? 
          <div className="head-teacher-comment"><p className="teacher-comment">Head Teacher's Comment</p>{ data.head_teacher_comment }</div>
         : null }
        { data.head_teacher_signature !== null ? 
          <div className="signature">
            <p className="sign">Signature</p>
            <div className="commentsss">{ data.head_teacher_signature }</div>
          </div>
        : null }
          <br></br>
        </div>
    </>
  );
};
export default Otherinfo;