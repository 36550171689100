import GradingViewLaurel from "./GradingViewLaurel";

const BehaviourLaurel = (props) => {
  const data = props.data;
  const gradingdata = props.gradingdata;
  console.log(gradingdata);
  const behaviourassstyle = {
    width: "30px",
    textAlign: "center",
    height: "6px",
    fontSize: "8px"
  };

  const tablestyle = {
    fontSize: "8px",
    padding: "0"
  }
  
  return (
    <>
      <div className="m-0 d-flex">
        <div className="table-container mx-1" style={tablestyle}>
          <p className="text-center my-0 py-0">Key to Grading</p>
          <table className="table-bordered">
            <tbody><tr>
              <th>AFFECTIVE DOMAIN</th>
              <th>5</th>
              <th>4</th>
              <th>3</th>
              <th>2</th>
              <th>1</th>
            </tr>
            {  data.politeness ?
            <tr>
              <td>Politeness</td>
              <td>{data.politeness === 1 ? <span>&#9989;</span> : null}</td>
              <td>{data.politeness === 2 ? <span>&#9989;</span> : null}</td>
              <td>{data.politeness === 3 ? <span>&#9989;</span> : null}</td>
              <td>{data.politeness === 4 ? <span>&#9989;</span> : null}</td>
              <td>{data.politeness === 5 ? <span>&#9989;</span> : null}</td>
            </tr>
            : null }
            {  data.honesty ?
            <tr>
              <td>Honesty</td>
              <td>{data.honesty === 1 ? <span>&#9989;</span> : null}</td>
              <td>{data.honesty === 2 ? <span>&#9989;</span> : null}</td>
              <td>{data.honesty === 3 ? <span>&#9989;</span> : null}</td>
              <td>{data.honesty === 4 ? <span>&#9989;</span> : null}</td>
              <td>{data.honesty === 5 ? <span>&#9989;</span> : null}</td>
            </tr>
            : null }
            {  data.punctuality ?
            <tr>
              <td>Punctuality</td>
              <td>{data.punctuality === 1 ? <span>&#9989;</span> : null}</td>
              <td>{data.punctuality === 2 ? <span>&#9989;</span> : null}</td>
              <td>{data.punctuality === 3 ? <span>&#9989;</span> : null}</td>
              <td>{data.punctuality === 4 ? <span>&#9989;</span> : null}</td>
              <td>{data.punctuality === 5 ? <span>&#9989;</span> : null}</td>
            </tr>
              : null }
            {  data.fluency ?
            <tr>
              <td>Fluency</td>
              <td>{data.fluency === 1 ? <span>&#9989;</span> : null}</td>
              <td>{data.fluency === 2 ? <span>&#9989;</span> : null}</td>
              <td>{data.fluency === 3 ? <span>&#9989;</span> : null}</td>
              <td>{data.fluency === 4 ? <span>&#9989;</span> : null}</td>
              <td>{data.fluency === 5 ? <span>&#9989;</span> : null}</td>
            </tr>
            : null }
            {  data.neatness ?
            <tr>
              <td>Neatness</td>
              <td>{data.neatness === 1 ? <span>&#9989;</span> : null}</td>
              <td>{data.neatness === 2 ? <span>&#9989;</span> : null}</td>
              <td>{data.neatness === 3 ? <span>&#9989;</span> : null}</td>
              <td>{data.neatness === 4 ? <span>&#9989;</span> : null}</td>
              <td>{data.neatness === 5 ? <span>&#9989;</span> : null}</td>
            </tr>
            : null }
            {  data.etiquette ?
            <tr>
              <td>Etiquette</td>
              <td>{data.etiquette === 1 ? <span>&#9989;</span> : null}</td>
              <td>{data.etiquette === 2 ? <span>&#9989;</span> : null}</td>
              <td>{data.etiquette === 3 ? <span>&#9989;</span> : null}</td>
              <td>{data.etiquette === 4 ? <span>&#9989;</span> : null}</td>
              <td>{data.etiquette === 5 ? <span>&#9989;</span> : null}</td>
            </tr>
            : null }
            {  data.attentiveness ?
            <tr>
              <td>Attentiveness in class</td>
              <td>{data.attentiveness === 1 ? <span>&#9989;</span> : null}</td>
              <td>{data.attentiveness === 2 ? <span>&#9989;</span> : null}</td>
              <td>{data.attentiveness === 3 ? <span>&#9989;</span> : null}</td>
              <td>{data.attentiveness === 4 ? <span>&#9989;</span> : null}</td>
              <td>{data.attentiveness === 5 ? <span>&#9989;</span> : null}</td>
            </tr>
            : null }
            {  data.obedience ?
            <tr>
              <td>Obedience</td>
              <td>{data.obedience === 1 ? <span>&#9989;</span> : null}</td>
              <td>{data.obedience === 2 ? <span>&#9989;</span> : null}</td>
              <td>{data.obedience === 3 ? <span>&#9989;</span> : null}</td>
              <td>{data.obedience === 4 ? <span>&#9989;</span> : null}</td>
              <td>{data.obedience === 5 ? <span>&#9989;</span> : null}</td>
            </tr>
            : null }
            {  data.self_control ?
            <tr>
              <td>Self control</td>
              <td>{data.self_control === 1 ? <span>&#9989;</span> : null}</td>
              <td>{data.self_control === 2 ? <span>&#9989;</span> : null}</td>
              <td>{data.self_control === 3 ? <span>&#9989;</span> : null}</td>
              <td>{data.self_control === 4 ? <span>&#9989;</span> : null}</td>
              <td>{data.self_control === 5 ? <span>&#9989;</span> : null}</td>
            </tr>
            : null }
            {  data.relationship_with_others ?
            <tr>
              <td>Relationship with others</td>
              <td>{data.relationship_with_others === 1 ? <span>&#9989;</span> : null}</td>
              <td>{data.relationship_with_others === 2 ? <span>&#9989;</span> : null}</td>
              <td>{data.relationship_with_others === 3 ? <span>&#9989;</span> : null}</td>
              <td>{data.relationship_with_others === 4 ? <span>&#9989;</span> : null}</td>
              <td>{data.relationship_with_others === 5 ? <span>&#9989;</span> : null}</td>
            </tr>
            : null }
          </tbody></table>
        </div>
        <div className="table-container mx-1" style={tablestyle}>
          <p className="text-center my-0 py-0">Key to Grading</p>
          <table className="table-bordered">
            <tbody>
              <tr>
                <th>PSYCHOMOTOR DOMAIN</th>
                <th>5</th>
                <th>4</th>
                <th>3</th>
                <th>2</th>
                <th>1</th>
              </tr>
              {  data.sports_and_gymnastics ?
              <tr>
                <td>Sports and Gymnastics</td>
                <td>{data.sports_and_gymnastics === 1 ? <span>&#9989;</span> : null}</td>
                <td>{data.sports_and_gymnastics === 2 ? <span>&#9989;</span> : null}</td>
                <td>{data.sports_and_gymnastics === 3 ? <span>&#9989;</span> : null}</td>
                <td>{data.sports_and_gymnastics === 4 ? <span>&#9989;</span> : null}</td>
                <td>{data.sports_and_gymnastics === 5 ? <span>&#9989;</span> : null}</td>
              </tr>
              : null }
              {  data.hand_writing ?
              <tr>
                <td>Hand writing</td>
                <td>{data.hand_writing === 1 ? <span>&#9989;</span> : null}</td>
                <td>{data.hand_writing === 2 ? <span>&#9989;</span> : null}</td>
                <td>{data.hand_writing === 3 ? <span>&#9989;</span> : null}</td>
                <td>{data.hand_writing === 4 ? <span>&#9989;</span> : null}</td>
                <td>{data.hand_writing === 5 ? <span>&#9989;</span> : null}</td>
              </tr>
              : null }
              {  data.arts_and_crafts ?
              <tr>
                <td>Arts and Crafts</td>
                <td>{data.arts_and_crafts === 1 ? <span>&#9989;</span> : null}</td>
                <td>{data.arts_and_crafts === 2 ? <span>&#9989;</span> : null}</td>
                <td>{data.arts_and_crafts === 3 ? <span>&#9989;</span> : null}</td>
                <td>{data.arts_and_crafts === 4 ? <span>&#9989;</span> : null}</td>
                <td>{data.arts_and_crafts === 5 ? <span>&#9989;</span> : null}</td>
              </tr>
              : null }
              {  data.eye_and_hand_coordination ?
              <tr>
                <td>Eye and hand coordination</td>
                <td>{data.eye_and_hand_coordination === 1 ? <span>&#9989;</span> : null}</td>
                <td>{data.eye_and_hand_coordination === 2 ? <span>&#9989;</span> : null}</td>
                <td>{data.eye_and_hand_coordination === 3 ? <span>&#9989;</span> : null}</td>
                <td>{data.eye_and_hand_coordination === 4 ? <span>&#9989;</span> : null}</td>
                <td>{data.eye_and_hand_coordination === 5 ? <span>&#9989;</span> : null}</td>
              </tr>
              : null }
              {  data.practical_skills ?
              <tr>
                <td>Practical skills</td>
                <td>{data.practical_skills === 1 ? <span>&#9989;</span> : null}</td>
                <td>{data.practical_skills === 2 ? <span>&#9989;</span> : null}</td>
                <td>{data.practical_skills === 3 ? <span>&#9989;</span> : null}</td>
                <td>{data.practical_skills === 4 ? <span>&#9989;</span> : null}</td>
                <td>{data.practical_skills === 5 ? <span>&#9989;</span> : null}</td>
              </tr>
              : null }
            </tbody>
          </table>
        </div>
        <div className="table-container" style={tablestyle}>
          <p className="text-center my-0 py-0">Key to Grading</p>
          <table className="table-bordered">
            <tbody>
              { gradingdata.map((grading) => 
              <GradingViewLaurel 
              key={grading.id}
              grading = {grading}

              />
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default BehaviourLaurel;