import { useParams, useLocation, useNavigate } from "react-router-dom";
import Behaviour from "./subcomponents/Behaviour";
import Otherinfo from "./subcomponents/Otherinfo";
import Assessment from "./subcomponents/Assessment";
const ResultView = (props) => {
  const location = useLocation();
  console.log(location.state);
  const admission = location.state.admission;
  const profile = location.state.profile;
  const school = location.state.school;
  const history = location.state.history;
  const behaviour = location.state.behaviour;
  const assessment = location.state.records;
  const grading = location.state.grading;
  const teacher = location.state.teacher;

  function convertToNumber(variable) {
    let number = parseFloat(variable); // or Number(variable)
    if (isNaN(number)) {
      return 0;
    }
    return number;
  }
  
  function getStudentPencentage(assessment){
    let totalfinal_grade = 0;
    let totalpossible_grade = 0;
    for (let i = 0; i < assessment.length; i++) {
      totalfinal_grade += convertToNumber(assessment[i].final_grade);
    }
    totalpossible_grade = assessment.length * 100;
    return ((totalfinal_grade/totalpossible_grade) * 100).toFixed(2);
  }
  
  console.log(behaviour);
  
  const alignstyle = {
    textAlign: "center"
  };
  
  return (
    <div className="student_result_holder_div" id="student_result_holder">
      <div className="body">
        <div className="side-bar-part">
          <div className="sidebar">
            <div className="school-logo">
              { school.school_logo == null ? null : <img src={ 'https://early.school/cnd/schools_assets/schools_images/schools_sites_images/'+school.school_logo} width="30" height="30" />}
            </div>
            <div className="student-image">
              { profile.profile_image == null ? null : <img src={ 'https://early.school/cnd/schools_assets/schools_images/students_passport_images/'+profile.profile_image } width="30" height="30" />}
            </div>
            <div className="student-name">
              <p className="fullname" style={alignstyle} >{profile.first_name} {profile.last_name}</p>
            </div>
            <div className="age">
              <b>
                <p className="student-age" style={alignstyle} >Student Age</p>
              </b>
              <div className="the-age" style={alignstyle} ></div>
            </div>
            <div className="weight">
              <b>
                <p className="student-weight" style={alignstyle} >Student Weight</p>
              </b>
              <div className="the-weight" style={alignstyle} >0</div>
            </div>
            <div className="position ">
              <b>
                <p className="student-position" style={alignstyle} >Student Position</p>
              </b>
              <div className="the-position" style={alignstyle} >0</div>
            </div>
            <div className="percentage ">
              <b>
                <p className="student-percentage" style={alignstyle} >Student Percentage</p>
              </b>
              <div className="the-percentage" style={alignstyle} >
                <p className="student_percentage_holder">{getStudentPencentage(assessment)}%</p>
              </div>
            </div>
            <div className="addmission-no ">
              <b>
                <p className="addmin-no" style={alignstyle} >Admission No</p>
              </b>
              <p className="No" style={alignstyle} >{ admission.admission_number}</p>
            </div>
            <div className="session-section">
              <b>
                <p className="sess-sect" style={alignstyle}>Academic Session</p>
              </b>
              <p className="Outcome-of-Sess-sect" style={alignstyle} >{ history.session_name }</p>
            </div>
            <div className="student-class ">
              <b>
                <p className="student-class" style={alignstyle} >Class</p>
              </b>
              <p className="the-class" style={alignstyle} >{ history.class_name }</p>
            </div>
            <div className="session-section ">
              <b>
                <p className="sess-sect" style={alignstyle} >Class Section</p>
              </b>
              <p className="Outcome-of-Sess-sect" style={alignstyle} >{ history.section_name }</p>
            </div>
            <div className="date-issue ">
              <b>
                <p className="the-dates" style={alignstyle} >Date Issued</p>
              </b>
              <p className="date" style={alignstyle} >{ new Date().toISOString().slice(0, 10) }</p>
            </div>
            <div className="Result-serials ">
              <b>
                <p className="sess-sect" style={alignstyle} >RESULT SERIAL NO</p>
              </b>
              <p className="the-serial" style={alignstyle} ></p>
            </div>
            <div className="TERMS ">
              <b>
                <p className="sess" style={alignstyle} >RESULT T&amp;C</p>
              </b>
              <p className="sess" style={alignstyle} ></p>
            </div>
            <div className="qrcode ">
              <b>
                <p className="qrcode" style={alignstyle} >RESULT Qrcode</p>
              </b>
              <div className="qrcd" style={alignstyle} ></div>
            </div>
          </div>
        </div>
        <div className="apart-from-side-bar">
          <div className="header">
            <div className="school-name ">
              <p className="the-school-name" style={alignstyle} >{school.school_name}</p>
            </div>
            <div className="address">
              <p className="school-add" style={alignstyle} >{school.school_address}</p>
            </div>
            <div className="school-email ">
              <p className="" style={alignstyle} >{school.school_email}</p>
            </div>
            <div className="school-web ">
              <p className="" style={alignstyle} >{school.school_url}</p>
            </div>
          </div>
          <div className="headerss ">
            <p className="headers" style={alignstyle} >School Report</p>
          </div>

          { assessment!==null ? 
            <Assessment 
              data= {assessment}
            />
            : null }

          { behaviour!==null ? 
            <Behaviour 
              key={behaviour.id}
              data= {behaviour}
            />
            : null }
          { behaviour!==null ? 
            <Otherinfo 
              key={behaviour.id}
              data= {behaviour}
              teacher= {teacher}
              grading ={grading}
            />
            : null }
            
        </div>
      </div>
    </div>
  );
};
export default ResultView;