import React, { useState } from 'react';
import { useLocation } from "react-router-dom";

const SubjectScoreFinalLaurel = (props) => {
  const location = useLocation();
  const gdata = props.gradingdata;
  const student_id = location.state.student_id;
  const [position, setPosition] = useState('');
  const [lowest, setLowest] = useState('');
  const [highest, setHighest] = useState('');

  async function getPosition(school_id, session_id, class_id, section_id, subject_id){
    const url = 'https://early.school:8001/api/result/extra';
    await fetch(url, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
      body: JSON.stringify(
        {
          schoolid: school_id,
          sessionid: session_id,
          classid: class_id,
          sectionid: section_id,
          subjectid: subject_id,
          studentid: student_id,
        }
      )
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => {
      if (!data.success){
        console.log(data.err);
        setPosition('');
        setLowest('');
        setHighest('');
      } else {
        console.log(data);
        setPosition(data.position);
        setLowest(data.lowestGrade);
        setHighest(data.highestGrade);
      };
    })
    .catch((err) => {
      console.log(err.message);
    });
  }

  function convertToNumber(variable) {
    let number = parseFloat(variable); // or Number(variable)
    if (isNaN(number)) {
      return 0;
    }
    return number;
  }

  function getGrade (gdata, total_percentage) {
    for (let g = 0; g<gdata.length; g++){
      if ((total_percentage >= gdata[g].minimum_mark) & (total_percentage <= gdata[g].maximum_mark)) return(gdata[g].name);
    }
  };
  
  function getRemark (gdata, total_percentage) {
    for (let g=0; g<gdata.length; g++) {
      if ((total_percentage >= gdata[g].minimum_mark) & (total_percentage <= gdata[g].maximum_mark)) return (gdata[g].description);
    }
  };
  
  const subjectName = props.subjectdata;
  const assessment = props.assessmentdata;

  let ft_first_grade = 0;
  let ft_second_grade = 0;
  let ft_third_grade = 0;
  let ft_fourth_grade = 0;
  let ft_exam_grade = 0;
  let ft_final_grade = 0;

  let st_first_grade = 0;
  let st_second_grade = 0;
  let st_third_grade = 0;
  let st_fourth_grade = 0;
  let st_exam_grade = 0;
  let st_final_grade = 0;

  let tt_first_grade = 0;
  let tt_second_grade = 0;
  let tt_third_grade = 0;
  let tt_fourth_grade = 0;
  let tt_exam_grade = 0;
  let tt_final_grade = 0;

  let total_final = 0;
  let section_subject_counter = 0;
  let total_percentage = 0;

  for (let j = 0; j < assessment['first'].length; j++){
    if (subjectName === assessment['first'][j].subject_name) {
      ft_first_grade = convertToNumber(assessment['first'][j].first_grade);
      ft_second_grade = convertToNumber(assessment['first'][j].second_grade);
      ft_third_grade = convertToNumber(assessment['first'][j].third_grade);
      ft_fourth_grade = convertToNumber(assessment['first'][j].fourth_grade);
      ft_exam_grade = convertToNumber(assessment['first'][j].exam_grade);
      ft_final_grade = convertToNumber(assessment['first'][j].final_grade);
      total_final += convertToNumber(assessment['first'][j].final_grade);
      section_subject_counter++;
    }
  }

  for (let k = 0; k < assessment['second'].length; k++){
    if (subjectName === assessment['second'][k].subject_name) {
      st_first_grade = convertToNumber(assessment['second'][k].first_grade);
      st_second_grade = convertToNumber(assessment['second'][k].second_grade);
      st_third_grade = convertToNumber(assessment['second'][k].third_grade);
      st_fourth_grade = convertToNumber(assessment['second'][k].fourth_grade);
      st_exam_grade = convertToNumber(assessment['second'][k].exam_grade);
      st_final_grade = convertToNumber(assessment['second'][k].final_grade);
      total_final += convertToNumber(assessment['second'][k].final_grade);
      section_subject_counter++;
    }
  }

  for (let l = 0; l < assessment['third'].length; l++){
    if (subjectName === assessment['third'][l].subject_name){
      tt_first_grade = convertToNumber(assessment['third'][l].first_grade);
      tt_second_grade = convertToNumber(assessment['third'][l].second_grade);
      tt_third_grade = convertToNumber(assessment['third'][l].third_grade);
      tt_fourth_grade = convertToNumber(assessment['third'][l].fourth_grade);
      tt_exam_grade = convertToNumber(assessment['third'][l].exam_grade);
      tt_final_grade = convertToNumber(assessment['third'][l].final_grade);
      total_final += convertToNumber(assessment['third'][l].final_grade);
      getPosition(assessment['third'][l].school_id, assessment['third'][l].session_id, assessment['third'][l].class_id, assessment['third'][l].section_id, assessment['third'][l].subject_id);
      section_subject_counter++;
    }
  }

  total_percentage = (total_final / (section_subject_counter * 100)) * 100;

  const grade = getGrade(gdata, total_percentage);
  const remark = getRemark(gdata, total_percentage);
  
  const style = {
    width: "30px",
    textAlign: "center",
    height: "8px",
    fontSize: "12px"
  };
  
  const subjectstyle = {
    width: "200px", 
    fontSize: "15px"
  }
  
  return (
    <>
      <tr scope="row">
        <td>{subjectName}</td>
        <td>15</td>
        <td>{ tt_first_grade }</td>
        <td>15</td>
        <td>{ tt_second_grade }</td>
        <td>10</td>
        <td>{ tt_third_grade }</td>
        <td>60</td>
        <td>{ tt_exam_grade }</td>
        <td>100</td>
        <td>{ tt_final_grade }</td>
        <td>{ highest }</td>
        <td>{ lowest }</td>
        <td>{ position }</td>
        <td>{ grade }</td>
        <td className="text-uppercase">{ remark }</td>
      </tr>
    </>
  );
};
export default SubjectScoreFinalLaurel;